import { useEffect, useState } from 'react';
import { useConfig } from '../contexts/ConfigContext';
import { useConfirmation } from '../contexts/ConfirmationContext';
import { useRequestId } from '../contexts/RequestContext';
import { request } from '../lib/request';

export type UseSubmitConfirmedAccountsState = {
    loading: boolean;
    submitted: boolean;
    err: string | Error | null;
};
let once = false;

export const useSubmitConfirmedAccounts = (): UseSubmitConfirmedAccountsState => {
    const config = useConfig();
    const requestId = useRequestId();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [err, setErr] = useState<string | Error | null>(null);
    const { expected, confirmed, isConfirmed } = useConfirmation();
    useEffect(() => {
        if (!once && requestId && expected.length > 0 && !isConfirmed && confirmed.length === expected.length) {
            setLoading(true);
            setSubmitted(true);
            once = true;
            request(`${config.aocEndpoint}/UpdateOPR`, {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    requestId,
                    confirmed: confirmed.map((account) => account.token),
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error(`Returned response code ${response.status}`);
                    }
                })
                .catch((err) => {
                    setErr(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [requestId, expected, isConfirmed, confirmed, config]);

    return {
        loading,
        submitted,
        err,
    };
};
