import { Header } from '@amzn/awsui-components-react';
export const ConfirmationHeader = () => {
    return (
        <>
            <Header
                description="Help us keep your data secure. Sign in to each account to confirm ownership."
                data-testid="confirm-infoheader"
            >
                Confirm account ownership
            </Header>
        </>
    );
};
