import { useEffect, useState } from 'react';
import { useConfig } from '../contexts/ConfigContext';
import { useRequestId } from '../contexts/RequestContext';
import { ExpectedAccount } from '../lib/aoc';
import { request } from '../lib/request';

export type ExpectedState = {
    expected: ExpectedAccount[] | null;
    loading: boolean;
    notFound: boolean;
    isConfirmed: boolean;
};

export const useExpectedAccounts = (): ExpectedState => {
    const config = useConfig();
    const requestId = useRequestId();
    const [loading, setIsLoading] = useState<boolean>(true);
    const [expected, setExpected] = useState<ExpectedAccount[] | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    useEffect(() => {
        if (requestId) {
            request(`${config.aocEndpoint}/GetPartialOPR?requestId=${requestId}`, {
                method: 'GET',
                redirect: 'follow',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.status === 404) {
                        setNotFound(true);
                    }
                    return response.json();
                })
                .then(({ expected, isConfirmed }: { expected: ExpectedAccount[]; isConfirmed: boolean }) => {
                    setExpected(expected);
                    setIsConfirmed(isConfirmed);
                })
                .catch((err) => {
                    console.log(err);
                    setNotFound(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [config, requestId]);

    return {
        expected,
        loading,
        notFound,
        isConfirmed,
    };
};
