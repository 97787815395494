import { createContext, ReactNode, useContext, useState } from 'react';
import { IDP } from '../lib/aoc';
import { signout } from '../lib/auth';
import { getProvider } from '../service/providers';
import { useConfig } from './ConfigContext';
import { useConfirmation } from './ConfirmationContext';

export type DuplicationWarning = {
    permDismissed: boolean;
    presentInHeader: boolean;
    signOutAndDismiss: (idp: IDP) => void;
};

export const DuplicationWarningContext = createContext<DuplicationWarning | undefined>(undefined);

export const useDuplicationWarning = (): DuplicationWarning => {
    return useContext(DuplicationWarningContext) as DuplicationWarning;
};

export const DuplicationWarningBoundary = ({ children }: { children: ReactNode }) => {
    const [permDismissed, setPermDismissed] = useState<boolean>(false);
    const { duplicateConfirmation } = useConfirmation();
    const config = useConfig();

    return (
        <DuplicationWarningContext.Provider
            value={{
                permDismissed,
                presentInHeader: duplicateConfirmation !== undefined,
                signOutAndDismiss: (idp) => {
                    signout(config, getProvider(idp));
                    setPermDismissed(true);
                },
            }}
        >
            {children}
        </DuplicationWarningContext.Provider>
    );
};
