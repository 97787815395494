import { SpaceBetween } from '@amzn/awsui-components-react';
import { Layout } from '../components/common/Layout';
import { AllConfirmedHeader } from '../components/confirmation/AllConfirmedHeader';
import { ConfirmationHeader } from '../components/confirmation/ConfirmationHeader';
import { ConfirmationList } from '../components/confirmation/ConfirmationList';
import { DuplicationHeader } from '../components/confirmation/DuplicationHeader';
import { ConfirmationBoundary } from '../contexts/ConfirmationContext';
import { DuplicationWarningBoundary } from '../contexts/DuplicationWarningContext';
import { RequestBoundary } from '../contexts/RequestContext';
import { SubmitConfirmationBoundary } from '../contexts/SubmitConfirmationContext';

export const Confirm = () => {
    return (
        <>
            <RequestBoundary>
                <ConfirmationBoundary>
                    <SubmitConfirmationBoundary>
                        <DuplicationWarningBoundary>
                            <DuplicationHeader />
                            <AllConfirmedHeader />
                            <Layout>
                                <SpaceBetween direction="vertical" size={'s'}>
                                    <ConfirmationHeader />
                                    <ConfirmationList />
                                </SpaceBetween>
                            </Layout>
                        </DuplicationWarningBoundary>
                    </SubmitConfirmationBoundary>
                </ConfirmationBoundary>
            </RequestBoundary>
        </>
    );
};
