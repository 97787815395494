import ReactDOM from 'react-dom/client';
import App from './App';
import TopHeader from './components/common/TopHeader';
import { ConfigBoundary } from './contexts/ConfigContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as configService from './service/config';

const initApp = async () => {
    const config = await configService.init();
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <>
            <TopHeader />
            <div>
                <ConfigBoundary config={config}>
                    <App />
                </ConfigBoundary>
            </div>
        </>,
    );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

(() => {
    initApp();
})();
