import { Button, Flashbar } from '@amzn/awsui-components-react';
import { useConfirmation } from '../../contexts/ConfirmationContext';
import { useDuplicationWarning } from '../../contexts/DuplicationWarningContext';
import { getProvider } from '../../service/providers';

export const DuplicationHeader = () => {
    const { duplicateConfirmation } = useConfirmation();
    const duplicationWarning = useDuplicationWarning();
    if (!duplicateConfirmation || !duplicationWarning.presentInHeader || duplicationWarning.permDismissed) {
        return <></>;
    }
    return (
        <Flashbar
            items={[
                {
                    header: <>You’ve already confirmed that account</>,
                    content: (
                        <>
                            You may already be signed in with {getProvider(duplicateConfirmation).shortName}. Sign out
                            to enable sign in.
                        </>
                    ),
                    action: (
                        <Button
                            iconAlign="right"
                            iconName="external"
                            onClick={() => duplicationWarning.signOutAndDismiss(duplicateConfirmation)}
                        >
                            Sign out of {getProvider(duplicateConfirmation).shortName}
                        </Button>
                    ),
                    dismissible: false,
                    type: 'warning',
                },
            ]}
        />
    );
};
