import { Container, Header, StatusIndicator } from '@amzn/awsui-components-react';
import { Account } from '../../lib/aoc';
import { getProvider } from '../../service/providers';

export type StepHeaderOptions = {
    stepNumber: number;
    account: Account;
    confirmed?: boolean;
};
export const StepHeader = ({ stepNumber, account, confirmed }: StepHeaderOptions) => {
    let description = '';
    if (confirmed) {
        description = `Sign in method: ${getProvider(account.idp).readableName}${
            account.email ? `| Email: ${account.email}` : ''
        }`;
    } else {
        description = `Requested sign in method: ${getProvider(account.idp).readableName}`;
    }
    return (
        <Container>
            <Header
                actions={account.email ? <StatusIndicator> Confirmed </StatusIndicator> : <></>}
                description={description}
            >
                Step {stepNumber}
            </Header>
        </Container>
    );
};
