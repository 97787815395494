import { useEffect, useState } from 'react';
import { useConfig } from '../contexts/ConfigContext';
import { useRequestId } from '../contexts/RequestContext';
import { ConfirmedAccount, CONFIRMED_TOKENS } from '../lib/aoc';
import { request } from '../lib/request';
import { extractInfoFromToken } from '../lib/tokenExtraction';
import { trimUrlForRedirect } from '../lib/urlUtil';

type RefreshTokenList = string[];

export type ConfirmedAccountsState = {
    confirmed: ConfirmedAccount[];
    loading: boolean;
};

export const useConfirmedAccounts = (): ConfirmedAccountsState => {
    const config = useConfig();
    const requestId = useRequestId();
    const [loading, setLoading] = useState<boolean>(true);
    const [confirmed, setConfirmed] = useState<ConfirmedAccount[]>([]);

    useEffect(() => {
        let stayLoading = false;

        try {
            if (!requestId) {
                return;
            }

            const confirmedValue = window.localStorage.getItem(CONFIRMED_TOKENS(requestId));
            if (!confirmedValue) {
                return;
            }

            const refreshTokens = JSON.parse(atob(confirmedValue)) as RefreshTokenList;
            if (!refreshTokens) {
                return;
            }

            stayLoading = true;
            Promise.all(
                refreshTokens.map((token) => {
                    const params = new URLSearchParams();
                    params.append('client_id', config.clientID);
                    params.append('grant_type', 'refresh_token');
                    params.append('refresh_token', token);
                    params.append('redirect_uri', trimUrlForRedirect(window.location.href));
                    return request(`${config.gandalfCognitoEndpoint}/oauth2/token`, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        method: 'POST',
                        body: params,
                    });
                }),
            )
                .then((requsts) => {
                    return Promise.all(requsts.map((request) => request.json()));
                })
                .then((tokens) => {
                    setConfirmed(
                        tokens.map((token, i) => {
                            return {
                                token: token.id_token,
                                refreshToken: refreshTokens[i],
                                ...extractInfoFromToken(token.id_token),
                            } as ConfirmedAccount;
                        }),
                    );
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (err) {
            console.log(err);
            window.localStorage.remove(CONFIRMED_TOKENS(requestId));
        } finally {
            setLoading(stayLoading);
        }
    }, [config, requestId]);

    return {
        loading,
        confirmed,
    };
};
