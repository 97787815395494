import { useEffect, useState } from 'react';
import { useAdminAuth } from '../contexts/AdminAuthContext';
import { Account } from '../lib/aoc';
import { request } from '../lib/request';
import { AOCConfig } from '../service/config';

export type CreateOPRState = {
    loading: boolean;
    requestId: string | null;
    err: string | Error | undefined;
};

export const useCreateOPR = (config: AOCConfig, caseId: string, accounts: Account[]) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [requestId, setRequestId] = useState<string | null>(null);
    const [err, setErr] = useState<string | Error | null>(null);
    const authContext = useAdminAuth();

    useEffect(() => {
        setLoading(true);
        if (caseId && accounts.length > 0) {
            request(`${config.aocEndpoint}/CreateOPR`, {
                method: 'POST',
                mode: 'cors',
                redirect: 'follow',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${authContext.token}`,
                },
                body: JSON.stringify({
                    caseId,
                    accounts,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error(`Response returned with error ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    setRequestId(data.requestId);
                })
                .catch((err) => {
                    setErr(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [config, caseId, accounts, authContext]);

    return {
        loading,
        requestId,
        err,
    };
};
