import { Flashbar } from '@amzn/awsui-components-react';
import { useConfirmation } from '../../contexts/ConfirmationContext';

export const AllConfirmedHeader = () => {
    const { confirmed, expected } = useConfirmation();
    if (confirmed.length !== expected.length) return <></>;

    return (
        <Flashbar
            items={[
                {
                    header: (
                        <>
                            Your account ownership confirmation was successful. Customer support has been notified. You
                            may now close this page.
                        </>
                    ),
                    dismissible: false,
                    type: 'success',
                },
            ]}
        />
    );
};
