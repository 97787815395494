import { Container, Header, ProgressBar, StatusIndicator } from '@amzn/awsui-components-react';
import { useConfirmation } from '../../contexts/ConfirmationContext';

export const ConfirmationProgress = () => {
    const confirmationContext = useConfirmation();
    const numberConfirmed = confirmationContext.confirmed.length;
    const numberExpected = confirmationContext.expected.length;
    const accountLabel = `Account${numberExpected > 1 ? 's' : ''}`;
    return (
        <Container
            header={
                numberConfirmed === numberExpected ? (
                    <Header description={<StatusIndicator>Confirmed</StatusIndicator>}>
                        {' '}
                        {numberExpected} {accountLabel} Requested{' '}
                    </Header>
                ) : (
                    <ProgressBar
                        value={(numberConfirmed / numberExpected) * 100}
                        label={`${numberExpected} ${accountLabel} Requested`}
                        additionalInfo={`${numberConfirmed} out of ${numberExpected} ${accountLabel.toLowerCase()} confirmed.`}
                    />
                )
            }
            disableContentPaddings
            data-testid="confirm-container"
        />
    );
};
