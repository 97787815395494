import {
    Button,
    Container,
    Form,
    Header,
    Icon,
    SpaceBetween,
    Spinner,
    TextContent,
} from '@amzn/awsui-components-react';
import { useCreateOPR } from '../../hooks/useCreateOPR';
import { AOCConfig } from '../../service/config';
import { getProvider } from '../../service/providers';
import { OPRFormData } from './CreateOPRForm';

export const CreateOPRSubmit = ({
    config,
    formData,
    clearForm,
}: {
    config: AOCConfig;
    formData: OPRFormData;
    clearForm: () => void;
}) => {
    const createOPRState = useCreateOPR(config, formData.caseID, formData.accounts);
    const link = `${window.location.href.split('?')[0].replace(/\/?create\/?/, '')}?requestId=${
        createOPRState.requestId as string
    }`;

    return (
        <>
            {createOPRState.loading ? (
                <Spinner />
            ) : createOPRState.err ? (
                <p>An error has occured, please try again</p>
            ) : (
                <Form
                    actions={
                        <Button formAction="submit" variant="primary" onClick={clearForm}>
                            New request
                        </Button>
                    }
                >
                    <Container header={<Header>Request Created</Header>}>
                        <TextContent>
                            <h3>Request Link</h3>
                            <p>Your customer may complete the request using this link.</p>
                            <p>
                                <a href={link}>{link}</a>
                                <Button variant="link" onClick={() => navigator.clipboard.writeText(link)}>
                                    <Icon name="copy" /> Copy link
                                </Button>{' '}
                            </p>
                        </TextContent>
                    </Container>
                    <Container disableHeaderPaddings>
                        <SpaceBetween direction="vertical" size={'s'}>
                            <TextContent>
                                <h3>Ticket URL</h3>
                                <p>
                                    <a href={formData.ticketUrl}>{formData.ticketUrl}</a>
                                </p>
                            </TextContent>
                            {formData.accounts.map((account, index) => {
                                return (
                                    <TextContent key={index}>
                                        <h3>Account {index + 1}</h3>
                                        <p>Email: {account.email}</p>
                                        <p>Sign in method: {getProvider(account.idp).readableName}</p>
                                    </TextContent>
                                );
                            })}
                        </SpaceBetween>
                    </Container>
                </Form>
            )}
        </>
    );
};
