export type IDP = string;

export const CONFIRMED_TOKENS = (requestId: string) => `confirmed_${requestId}`;

export type Account = {
    idp: IDP,
    email?: string
}

export type ExpectedAccount = Account & {
    email?: never
}

export type ConfirmedAccount = Account & {
    token?: string,
    refreshToken?: string
}

export type AOCState = {
    requestId: string | undefined,
    expected: IDP[],
    confirmed: ConfirmedAccount[]
}