import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from './components/common/ErrorBoundary';

import { Confirm } from './pages/Confirm';
import { Create } from './pages/Create';

export const App = () => {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Switch>
                    <Route exact path={['/', '/logout']} render={() => <Confirm />} />
                    <Route exact path="/create" render={() => <Create />} />
                </Switch>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default App;
