import { useState } from 'react';
import { Layout } from '../components/common/Layout';
import { CreateOPRForm, OPRFormData } from '../components/create/CreateOPRForm';
import { CreateOPRSubmit } from '../components/create/CreateOPRSubmit';
import { AdminAuthBoundary } from '../contexts/AdminAuthContext';
import { useConfig } from '../contexts/ConfigContext';

export const Create = () => {
    const [formData, setFormData] = useState<OPRFormData | null>(null);
    const config = useConfig();

    return (
        <>
            <AdminAuthBoundary>
                <Layout>
                    {formData ? (
                        <CreateOPRSubmit config={config} clearForm={() => setFormData(null)} formData={formData} />
                    ) : (
                        <CreateOPRForm onSubmit={(formData) => setFormData(formData)} />
                    )}
                </Layout>
            </AdminAuthBoundary>
        </>
    );
};
