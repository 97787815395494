import { isDevelopment } from '../util/env';
import { retry } from '../util/promise';

export type AOCConfig = {
    gandalfEndpoint: string;
    gandalfCognitoEndpoint: string;
    clientID: string;
    aocEndpoint: string;
};

const CONFIG_URI = '/settings.json';

let cachedConfig: AOCConfig;

export const init = async (): Promise<AOCConfig> => {
    try {
        if (isDevelopment) {
            const response = await retry(() => import('../devconfig/settings.json'));
            return (cachedConfig = response.default);
        } else {
            const response = await retry(() => fetch(CONFIG_URI), {
                retries: 3,
                interval: 100,
            });
            return (cachedConfig = await response.json());
        }
    } catch (err: unknown) {
        throw new Error(`Failed to fetch config: ${(err as Error).message}`);
    }
};

export const get = (): AOCConfig => {
    if (!cachedConfig) throw new Error('Need to run init.');
    return cachedConfig;
};
