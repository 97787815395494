import { Flashbar, Spinner } from '@amzn/awsui-components-react';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useCodeAuthorization } from '../hooks/useCodeAuthorization';
import { signin } from '../lib/auth';
import { useConfig } from './ConfigContext';
export type AdminAuthState = {
    token: string | null;
};

export const AdminAuthContext = createContext<AdminAuthState>({
    token: null,
});

export const useAdminAuth = (): AdminAuthState => {
    return useContext(AdminAuthContext);
};

export const AdminAuthBoundary = ({ children }: { children: ReactNode }) => {
    const [adminAuthState, setAdminAuthState] = useState<AdminAuthState>({ token: null });
    const codeAuthState = useCodeAuthorization();
    const config = useConfig();

    if (!adminAuthState.token) {
        if (codeAuthState.loading) {
            return <Spinner />;
        } else {
            if (codeAuthState.err) {
                return (
                    <Flashbar
                        items={[
                            {
                                header: 'Failed to authenticate as Midway user.',
                                type: 'error',
                                dismissible: false,
                                id: 'message_1',
                            },
                        ]}
                    />
                );
            } else if (codeAuthState.confirmedAccount) {
                if (
                    codeAuthState.confirmedAccount.idp !== 'AmazonSystemFederate' ||
                    !codeAuthState.confirmedAccount.token
                ) {
                    return (
                        <Flashbar
                            items={[
                                {
                                    header: 'You must login as a Midway user.',
                                    content: 'Reload the page to try again.',
                                    type: 'error',
                                    dismissible: false,
                                    id: 'message_1',
                                },
                            ]}
                        />
                    );
                }
                setAdminAuthState({
                    token: codeAuthState.confirmedAccount.token,
                });
            } else {
                signin(config, 'AmazonSystemFederate');
            }
            return <></>;
        }
    }
    return <AdminAuthContext.Provider value={adminAuthState}>{children}</AdminAuthContext.Provider>;
};
