import { logger } from './logger';

type RetryOptions = {
    retries: number;
    interval: number;
    expBackoff: boolean;
    jitter: boolean;
    retryPredicate?: (error: Error) => boolean;
};

/**
 * Allows you tap into a Promise's `then` function without having to worry
 * about stopping values passed in the promise chain.
 *
 * @example
 * Promise.resolve('Hello')
 *   .then(tap(res => console.log(res))) // "Hello"
 *   .then(res => console.log(res))      // "Hello"
 *   .then(res => console.log(res));     // undefined
 *
 * @param {Function} tapper
 * @returns {Function<Promise>}
 */
export const tap =
    (tapper: Function): any =>
    <T>(results: T): Promise<T> => {
        tapper(results);
        return Promise.resolve(results);
    };

/**
 * Returns a promise that resolves after `ms`.
 *
 * @param {Number} [ms] Timeout in ms
 * @returns {Promise}
 */
export const delay = (ms?: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Checks if the passed object is a Promise.
 *
 * @param {Object} object
 * @returns {Boolean}
 */
export const isPromise = (object: any): boolean =>
    // There is currently no great way to assert this absolutely. Right now the
    // existance of a then-able function is the only consistent pattern to check.
    Boolean(object && object.then && typeof object.then === 'function');

const calculateDelay = (options: RetryOptions, attempt: number) => {
    // Avoid delays when running test suite.
    if (process?.env?.NODE_ENV === 'test') return 0;

    const expBackoff = options.expBackoff ? Math.pow(2, attempt) : 1;
    const jitter = options.jitter ? Math.random() : 1;
    return Math.floor(options.interval * jitter * expBackoff);
};

const shouldRetry = (options: RetryOptions, error: Error) => {
    return options.retryPredicate ? options.retryPredicate(error) : true;
};

/**
 * Wraps a function that returns a promise with retry mechanics.
 *
 * @param {Function} asyncFn Function that returns a promise
 * @param {Object} [options={}]
 * @param {Number} [options.retries=3]
 * @param {Number} [options.interval=100]
 * @param {Boolean} [options.expBackoff=true] Adds exponential backoff between retries
 * @param {Boolean} [options.jitter=true] Adds jitter to retries
 * @param {Function<Boolean>} [options.retryPredicate] Fine tune when to retry, gets passed the caught error.
 * @returns {Promise}
 */
export const retry = (asyncFn: Function, options = {}, attempt = 1) => {
    const defaultOptions = {
        retries: 3,
        interval: 100,
        expBackoff: true,
        jitter: true,
    };
    const opts: RetryOptions = { ...defaultOptions, ...options };
    const promise = asyncFn();

    if (!isPromise(promise)) {
        logger.error('[retry] The function needs to return a Promise.');
        throw new Error('asyncFn did not resolve to a Promise');
    }

    return promise.catch((err: Error) => {
        logger.debug('[retry] Caught error.', err);

        if (!shouldRetry(opts, err)) {
            logger.debug('[retry] retryPredicate opted not to retry:', err);
            return Promise.reject(err);
        }

        if (opts.retries === 0) {
            logger.debug('[retry] No more retries, failing.');
            return Promise.reject(err);
        }

        return delay(calculateDelay(opts, attempt)).then(() => {
            logger.debug(`[retry] Retrying async call, ${opts.retries} attempts left.`);
            return retry(
                asyncFn,
                {
                    ...opts,
                    retries: opts.retries - 1,
                },
                attempt + 1,
            );
        });
    });
};
