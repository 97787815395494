import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Icon,
    Input,
    RadioGroup,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { Account } from '../../lib/aoc';
import { getProvidersIncludingNone } from '../../service/providers';

// This will match any caseconsole url with the expectation that the caseid is at the end of the url (eg caseconsole.com/caseid)
export const TICKET_URL_REGEX = /^.*caseconsole.*\/(?<caseid>[a-zA-Z0-9\\-]+)(\/)?$/;

// To give freedom to the CSAgent and customer, the email follows a very basic regex.
export const EMAIL_REGEX = /.+@.+/;

export type OPRFormData = {
    caseID: string;
    ticketUrl: string;
    accounts: Account[];
};

export const CreateOPRForm = ({ onSubmit }: { onSubmit: (data: OPRFormData) => void }) => {
    const [ticketUrl, setTicketUrl] = useState<string>('');
    const [accounts, setAccounts] = useState<Account[]>([{ idp: 'None', email: '' }]);
    const [ticketUrlError, setTicketUrlError] = useState<string | undefined>(undefined);
    const [accountError, setAccountError] = useState<Record<string, string | null>>({});
    const providerList = getProvidersIncludingNone();

    const getCaseIDFromUrl = (url: string): string | undefined => url.match(TICKET_URL_REGEX)?.groups?.caseid;
    const checkTicketURL = (): string | undefined => {
        const caseID = getCaseIDFromUrl(ticketUrl);
        if (!caseID) setTicketUrlError('Invalid case url');
        return caseID;
    };

    const checkAccountEmail = (account: Account, accountNumber: number) => {
        if (!account.email?.match(EMAIL_REGEX)) {
            setAccountError({
                ...accountError,
                [`${accountNumber}`]: 'Invalid email',
            });
            return false;
        }
        return true;
    };

    const submit = () => {
        let fail = false;
        const caseID = checkTicketURL();
        if (!caseID) {
            fail = true;
        }

        const findAccountErrors = {} as Record<string, string>;
        accounts.forEach((account, accountNumber) => {
            if (!checkAccountEmail(account, accountNumber)) {
                fail = true;
            }
        });
        setAccountError(findAccountErrors);

        if (!fail) {
            onSubmit({ caseID: caseID!, ticketUrl, accounts });
        }
    };
    return (
        <>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            formAction="none"
                            variant="normal"
                            onClick={(e) => {
                                setAccounts([...accounts, { idp: 'None', email: '' }]);
                            }}
                        >
                            + Add Account
                        </Button>
                        <Button variant="primary" disabled={accounts.length === 0} onClick={submit}>
                            Submit
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <Header
                        variant="h1"
                        description="Protect customer data. Send a request to confirm account ownership."
                    >
                        Request account ownership confirmation
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container header={<Header>Ticket URL</Header>}>
                        <FormField
                            description="URL for the ticket you want to be updated when your customer completes confirmation."
                            label="Ticket URL"
                            errorText={ticketUrlError}
                        >
                            <Input
                                value={ticketUrl}
                                onBlur={checkTicketURL}
                                onChange={({ detail }) => {
                                    setTicketUrl(detail.value);
                                    setTicketUrlError(undefined);
                                }}
                            />
                        </FormField>
                    </Container>
                    <span>
                        <Container disableContentPaddings header={<Header>Accounts detail</Header>} />
                        {accounts.map((account, accountNumber) => {
                            return (
                                <Container key={accountNumber} disableHeaderPaddings>
                                    <Header variant="h2"> Account {accountNumber + 1} </Header>
                                    <SpaceBetween direction="vertical" size="m">
                                        <FormField
                                            label={`Email`}
                                            errorText={accountError[`${accountNumber}`] ? 'Required' : undefined}
                                        >
                                            <Input
                                                value={account.email ?? ''}
                                                onBlur={() => checkAccountEmail(account, accountNumber)}
                                                onChange={({ detail }) => {
                                                    setAccounts([
                                                        ...accounts.slice(0, accountNumber),
                                                        { ...account, email: detail.value },
                                                        ...accounts.slice(accountNumber + 1),
                                                    ]);
                                                    setAccountError({
                                                        ...accountError,
                                                        [`${accountNumber}`]: null,
                                                    });
                                                }}
                                            />
                                        </FormField>
                                        <FormField label={`Sign in method`}>
                                            <RadioGroup
                                                onChange={({ detail }) =>
                                                    setAccounts([
                                                        ...accounts.slice(0, accountNumber),
                                                        { ...account, idp: detail.value ?? 'AmazonSystemFederate' },
                                                        ...accounts.slice(accountNumber + 1),
                                                    ])
                                                }
                                                value={account.idp}
                                                items={providerList.map((provider) => {
                                                    return {
                                                        value: provider.idp,
                                                        label: provider.readableName,
                                                    };
                                                })}
                                            />
                                        </FormField>
                                        {accounts.length > 1 ? (
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setAccountError({
                                                        ...accountError,
                                                        [`${accountNumber}`]: null,
                                                    });
                                                    setAccounts([
                                                        ...accounts.slice(0, accountNumber),
                                                        ...accounts.slice(accountNumber + 1),
                                                    ]);
                                                }}
                                            >
                                                <Icon name="close" /> Remove Account
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </SpaceBetween>
                                </Container>
                            );
                        })}
                    </span>
                </SpaceBetween>
            </Form>
        </>
    );
};
