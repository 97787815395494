import { Box, ButtonDropdown, Icon } from '@amzn/awsui-components-react';
import globeIcon from '../../assets/globe.svg';

export const LocaleMenu = () => {
    return (
        <ButtonDropdown
            items={[
                {
                    id: 'english',
                    text: 'English',
                },
            ]}
            data-testid="header-localeMenu"
        >
            <Box margin={{ right: 'xs' }} display="inline">
                <Icon url={globeIcon} alt="" />
            </Box>
            {'English'}
        </ButtonDropdown>
    );
};
