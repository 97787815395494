import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Loading } from '../components/common/Loading';
import { useCodeAuthorization } from '../hooks/useCodeAuthorization';
import { useConfirmedAccounts } from '../hooks/useConfirmedAccounts';
import { useExpectedAccounts } from '../hooks/useExpectedAccounts';
import { ConfirmedAccount, CONFIRMED_TOKENS, ExpectedAccount, IDP } from '../lib/aoc';
import { useRequestId } from './RequestContext';

export type ConfirmationState = {
    expected: ExpectedAccount[];
    confirmed: ConfirmedAccount[];
    duplicateConfirmation: IDP | undefined;
    isConfirmed: boolean;
};

export const ConfirmationContext = createContext<ConfirmationState>({
    expected: [],
    confirmed: [],
    duplicateConfirmation: undefined,
    isConfirmed: false,
});

export const useConfirmation = () => {
    return useContext(ConfirmationContext);
};

export const ConfirmationBoundary = ({ children }: { children: ReactNode }) => {
    const [duplicateConfirmation, setDuplicateConfirmation] = useState<IDP | undefined>(undefined);
    const expectedState = useExpectedAccounts();
    const confirmedState = useConfirmedAccounts();
    const codeAuthorizationState = useCodeAuthorization();
    const requestId = useRequestId();

    const expected = useMemo(() => {
        if (!expectedState.loading) {
            return expectedState.expected ?? [];
        }
        return [];
    }, [expectedState]);

    const { confirmed, duplicate } = useMemo(() => {
        let duplicate: IDP | undefined = undefined;
        if (!confirmedState.loading && !codeAuthorizationState.loading) {
            let accountsFromConfirmedState = [...(confirmedState.confirmed ?? [])] as ConfirmedAccount[];
            if (codeAuthorizationState.confirmedAccount) {
                if (
                    accountsFromConfirmedState.find(
                        (acc) =>
                            acc.email === codeAuthorizationState.confirmedAccount?.email &&
                            acc.idp === codeAuthorizationState.confirmedAccount?.idp,
                    )
                ) {
                    duplicate = codeAuthorizationState.confirmedAccount.idp;
                } else {
                    accountsFromConfirmedState.push(codeAuthorizationState.confirmedAccount);
                }
            }
            return { confirmed: accountsFromConfirmedState, duplicate };
        }
        return { confirmed: [], duplicate };
    }, [confirmedState, codeAuthorizationState]);

    useEffect(() => {
        if (!codeAuthorizationState.loading && !confirmedState.loading && confirmed.length > 0) {
            window.localStorage.setItem(
                CONFIRMED_TOKENS(requestId),
                btoa(
                    JSON.stringify(
                        confirmed.filter((acc) => acc.token && acc.refreshToken).map((acc) => acc.refreshToken),
                    ),
                ),
            );
        }
    }, [confirmedState, codeAuthorizationState, confirmed, requestId]);

    if (!duplicateConfirmation && duplicate) {
        setDuplicateConfirmation(duplicate);
    }
    if (expectedState.loading || confirmedState.loading || codeAuthorizationState.loading) {
        return <Loading />;
    }
    if (codeAuthorizationState.err) {
        throw codeAuthorizationState.err;
    }
    if (expectedState.notFound) {
        throw new Error('Did not find this request. Check the link you were given and try again.');
    }
    if (expected.length === 0) {
        throw new Error(
            'There was something wrong with this request. Please contact the Customer Service Agent fulfilling this request.',
        );
    }
    if (expectedState.isConfirmed && !confirmed.length) {
        confirmed.push(...expected);
    }
    return (
        <ConfirmationContext.Provider
            value={{ expected, confirmed, isConfirmed: expectedState.isConfirmed, duplicateConfirmation }}
        >
            {children}
        </ConfirmationContext.Provider>
    );
};
