import logo from '../../assets/logo-color-dark.svg';
import { LocaleMenu } from './LocaleMenu';
import styles from './TopHeader.module.css';

const TopHeader = () => {
    return (
        <header className={styles.wrapper}>
            <img className={styles.img} src={logo} alt={'logo'} />
            <LocaleMenu />
        </header>
    );
};

export default TopHeader;
