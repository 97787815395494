import { createContext, ReactNode, useContext } from 'react';
import { Loading } from '../components/common/Loading';
import { useSubmitConfirmedAccounts } from '../hooks/useSubmitConfirmedAccounts';

export type SubmitConfirmationState = {
    loading: boolean;
};

export const SubmitConfirmationContext = createContext<SubmitConfirmationState | undefined>(undefined);

export const useSubmitConfirmationContext = (): SubmitConfirmationState => {
    return useContext(SubmitConfirmationContext) as SubmitConfirmationState;
};

export const SubmitConfirmationBoundary = ({ children }: { children: ReactNode }) => {
    const submitAccounts = useSubmitConfirmedAccounts();
    if (submitAccounts.err) {
        throw submitAccounts.err;
    }
    if (submitAccounts.loading) {
        return <Loading />;
    }

    return (
        <SubmitConfirmationContext.Provider value={{ loading: submitAccounts.loading }}>
            {children}
        </SubmitConfirmationContext.Provider>
    );
};
