import {
    Alert,
    Button,
    Container,
    Grid,
    SpaceBetween,
    TextContent,
    Tiles,
    TilesProps,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useConfig } from '../../contexts/ConfigContext';
import { useConfirmation } from '../../contexts/ConfirmationContext';
import { useDuplicationWarning } from '../../contexts/DuplicationWarningContext';
import { ExpectedAccount, IDP } from '../../lib/aoc';
import { signin } from '../../lib/auth';
import { getProvider, getProviders } from '../../service/providers';

export const ConfirmationContainer = ({ account }: { account: ExpectedAccount }) => {
    const config = useConfig();
    const duplicationWarning = useDuplicationWarning();

    const [expanded, setExpanded] = useState<boolean>(account.idp === 'None');
    const [selectedIDP, setSelectedIDP] = useState<IDP>(account.idp);
    const { duplicateConfirmation, confirmed } = useConfirmation();
    const signInError = confirmed.find((cacc) => cacc.idp === selectedIDP);
    const providerList = getProviders();

    useEffect(() => {
        if (duplicateConfirmation) {
            setExpanded(true);
            setSelectedIDP(duplicateConfirmation);
        }
    }, [duplicateConfirmation]);

    return (
        <Container>
            {signInError !== undefined && !duplicationWarning.permDismissed ? (
                <Alert
                    header={`You may already be signed in with ${
                        getProvider(selectedIDP).shortName
                    }. Sign out to enable sign in.`}
                    type="warning"
                    dismissible={false}
                    action={
                        <Button
                            iconAlign="right"
                            iconName="external"
                            onClick={() => duplicationWarning.signOutAndDismiss(selectedIDP)}
                        >
                            Sign out of {getProvider(selectedIDP).shortName}
                        </Button>
                    }
                />
            ) : (
                <></>
            )}
            <br />
            <Grid gridDefinition={[{ colspan: { default: 12, m: 3 } }, { colspan: { default: 0, m: 9 } }]}>
                <SpaceBetween direction="vertical" size="s">
                    <TextContent>
                        <h3>Select a sign in method</h3>
                    </TextContent>
                    <Tiles
                        onChange={({ detail }) => setSelectedIDP(detail.value)}
                        value={selectedIDP}
                        columns={1}
                        items={(expanded ? providerList : [getProvider(account.idp)]).map(
                            (description): TilesProps.TilesDefinition => {
                                return {
                                    label: description.readableName,
                                    value: description.idp,
                                };
                            },
                        )}
                        data-testid="confirm-signinoptions"
                    />
                    {expanded ? (
                        <></>
                    ) : (
                        <Button variant="link" onClick={() => setExpanded(true)} data-testid="confirm-useanothermethod">
                            <TextContent>
                                <u>Use another method</u>
                            </TextContent>
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        disabled={selectedIDP === 'None'}
                        onClick={() => selectedIDP !== 'None' && signin(config, selectedIDP)}
                    >
                        Sign In
                    </Button>
                </SpaceBetween>
            </Grid>
        </Container>
    );
};
