import { useConfirmation } from '../../contexts/ConfirmationContext';
import { ConfirmationContainer } from './ConfirmationContainer';
import { ConfirmationProgress } from './ConfirmationProgress';
import { StepHeader } from './StepHeader';

export const ConfirmationList = () => {
    const { confirmed, expected } = useConfirmation();
    return (
        <>
            <ConfirmationProgress />
            {[
                ...confirmed.map((acc, index) => (
                    <StepHeader confirmed stepNumber={index + 1} account={acc} key={index} />
                )),
                ...expected
                    .filter((_, index) => index >= confirmed.length)
                    .map((acc, index) => {
                        const stepIndex = index + confirmed.length;
                        return (
                            <div key={stepIndex}>
                                <StepHeader stepNumber={stepIndex + 1} account={acc} />{' '}
                                {index === 0 ? <ConfirmationContainer key={stepIndex} account={acc} /> : <></>}{' '}
                            </div>
                        );
                    }),
            ]}
        </>
    );
};
