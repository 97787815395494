import { AOCConfig } from "../service/config";
import { IDPDescription } from "../service/providers";
import { trimUrlForRedirect } from "./urlUtil";

export const signin = (config: AOCConfig, idp: string) => {
    window.location.assign(`${config.gandalfEndpoint}/login?client_id=${config.clientID}&redirect_uri=${trimUrlForRedirect(window.location.href)}&response_type=code&identity_provider=${idp}`);
}

export const signout = (config: AOCConfig, provider: IDPDescription) => {
   window.open(`${config.gandalfCognitoEndpoint}/logout?client_id=${config.clientID}&logout_uri=${provider.signoutURL}`, '_blank');
}