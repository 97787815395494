import { IDP } from '../lib/aoc';
export type IDPDescription = {
    idp: IDP;
    readableName: string;
    shortName: string;
    description: string;
    signoutURL: string;
};

const providerList = [
    {
        idp: 'None',
        readableName: 'Not Specified',
        shortName: 'Not Specified',
        description: '',
        signoutURL: '',
    },
    {
        idp: 'AmazonSystemFederate',
        readableName: 'Amazon Employee Login',
        shortName: 'Amazon Employee',
        description: 'Sign in as an Amazon Employee',
        signoutURL: 'https://midway-auth.amazon.com/logout',
    },
    {
        idp: 'LoginWithAmazon',
        readableName: 'Login With Amazon',
        shortName: 'Amazon',
        description: 'Sign in with Amazon Retail Account',
        signoutURL: 'https://amazon.com/signout',
    },
    {
        idp: 'AWSPartnerNetwork',
        readableName: 'AWS Partner Network',
        shortName: 'APN',
        description: 'Sign in with AWS Partner Network',
        signoutURL: 'https://partnercentral.awspartner.com/secur/logout.jsp',
    },
] as IDPDescription[];

export const getProvidersIncludingNone = () => providerList;

export const getProviders = () => providerList.filter((provider) => provider.idp !== 'None');

export const getProvider = (idp: IDP): IDPDescription => {
    return providerList.find((name) => name.idp === idp) ?? providerList[0];
};
